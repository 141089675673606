import { SxProps, styled, useTheme } from '@cp/ui/theme';
import React, { FC, ReactNode } from 'react';
import { BannerServiceFeatureBody } from '@cp/entities/catalog';
import { RoutedLink } from '@youhelp/client.web/shared/ui/link';
import { Button } from '@cp/ds/src/components/button';
import { Box, Image } from '@cp/ui/components';
import { Typography } from '@cp/ds/src/components/typography';
import { Logo } from '#shared/ui/logo';
import stubAvatars from './assets/stub-avatars.png';

const Root = styled(Box, { name: 'AdvantageWidget' })({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  textAlign: 'center',
});

export interface BannerServiceFeatureProps {
  header?: ReactNode;
  sx?: SxProps;
}
export const BannerServiceFeature: FC<BannerServiceFeatureProps> = ({ header, ...props }) => {
  const theme = useTheme();
  return (
    <Root {...props}>
      {header}
      <BannerServiceFeatureBody
        columnsContent={[
          {
            c: (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                <Image alt="" height={stubAvatars.height} src={stubAvatars.src} width={stubAvatars.width} />
                <RoutedLink href="/nannies">
                  <Button as="span" size="small">
                    Найти няню
                  </Button>
                </RoutedLink>
              </Box>
            ),
          },
          {
            c: (
              <Box>
                <Typography variant="h2">
                  👍🏻&nbsp;97%&nbsp;<Typography variant="button">Положительных отзывов</Typography>
                </Typography>
                <Typography component="p" sx={{ color: theme.palette.text.secondary, m: '8px 0' }}>
                  1000 отзывов оставили родители за последний год. Из них 970 положительные.
                </Typography>
              </Box>
            ),
          },
          {
            c: (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Logo />
                  <Typography variant="button">— ваш надежный помощник</Typography>
                </Box>
                <Typography component="p" sx={{ color: theme.palette.text.secondary, m: '8px 0' }}>
                  Няня поиграет с ребенком, погуляет, встретит со школы, поможет с домашними заданиями, чтобы вы спокойно позанимались
                  своими делами.
                </Typography>
              </Box>
            ),
          },
        ]}
      />
    </Root>
  );
};
