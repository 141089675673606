import React from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds';
import { Grid } from '@cp/ds/src/components/grid';
import { useTheme } from '@cp/ds/src/hooks';

export interface BannerServiceFeatureBodyProps {
  columnsContent: { c: React.ReactNode }[];
}

export const BannerServiceFeatureBody: React.FC<BannerServiceFeatureBodyProps> = ({ columnsContent }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette['brand gray']}`,
        p: { xs: 2.5, [MOBILE_BREAKPOINT_THRESHOLD]: 5 },
        borderRadius: `${theme.borderRadius.medium}px`,
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: { xs: 'column', [MOBILE_BREAKPOINT_THRESHOLD]: 'row' },
        gap: { xs: 2.5, [MOBILE_BREAKPOINT_THRESHOLD]: 5 },
        textAlign: 'initial',
      }}
    >
      {columnsContent.map(({ c }, k) => (
        <Grid item key={k} md={4} xs={12}>
          {c}
        </Grid>
      ))}
    </Grid>
  );
};
